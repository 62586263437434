import React from "react"
import useTranslations from "../../utils/useTranslations"
import FeaturesList from "../../components/featuresList"
import ComparisonPanels from "../../components/comparisonPanels"

import heroImgWebp from "../../images/products/heroes/ui-web-mac.webp"
import heroImg from "../../images/products/heroes/ui-web-mac.png"
import heroImgWebp2x from "../../images/products/heroes/ui-web-mac@2x.webp"
import heroImg2x from "../../images/products/heroes/ui-web-mac@2x.png"


import Button from "../../components/button"
import { CompatibilityContext } from "../../components/compatibilityContext";

import classicScreenshotWebp from "../../images/products/heroes/ui-classic-mac.webp"
import classicScreenshot from "../../images/products/heroes/ui-classic-mac.png"
import classicScreenshotWebp2x from "../../images/products/heroes/ui-classic-mac@2x.webp"
import classicScreenshot2x from "../../images/products/heroes/ui-classic-mac@2x.png"

import Logo from "../../components/logo"
import warning from "../../images/icons/warning.svg"
import backgrounds from "../../components/backgrounds"
import TipsFaq from "../../components/tipsFaq"
import LocalizedLink from "../../components/localizedLink"

const heroImgAlt = "Download µTorrent Web for your Mac computer"
const hero2ImgAlt = "Download µTorrent Classic for your Mac computer."

const webLink = "/web/downloads/complete/track/stable/os/mac/"
const classicLink = "/downloads/complete/track/stable/os/mac/"

class BittorrentWebMac extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      isMac: true,
    };

    this.handleWebClick = this.handleWebClick.bind(this)
    this.handleClassicClick = this.handleClassicClick.bind(this)
  }

  handleClassicClick = (e, link) => {
    this.context(e, 'downloading', "Classic", ["Osx"], link)

  }

  handleWebClick = (e, link) => {
    this.context(e, 'downloading', "Web", ["Osx"], link)
  }

  render(){

    const { text, pageName } = this.props.pageContext
    const t = useTranslations(text)
    const { pathname }  = this.props.location

    return (
      <>
        <div className="top-section-container pt-md-2">

          <div className="container text-dark py-2">

            <div className="row text-center mt-4 my-sm-4 justify-content-center">
              <div className="col-md-6 mt-md-4 order-md-1 px-md-0">
              	<Logo secondaryColor="black" tag="h1" productName="Web for Mac" className="mb-4" fsMax={28} fsCoefficient={19}/>
              	
                {/*<img src={heroImg} alt={heroImgAlt} className="img-fluid d-block d-md-none mb-sm-2"></img>*/}

                <picture>
                  <source srcSet={`${heroImgWebp} 1x, ${heroImgWebp2x} 2x`} type="image/webp"></source> 
                  <img srcSet={`${heroImg} 1x, ${heroImg2x} 2x`} src={heroImg} alt={heroImgAlt} className="img-fluid d-block d-md-none shadow-lg rounded-xl"></img>
                </picture>
                <div style={{fontSize: '10px'}} className="d-block d-md-none mt-2 mb-4"><a id="web-mac-bt-now-mobile" href="https://now.bt.co/" target="_blank" rel="noopenner noreferrer" className="text-dark text-decoration-none">now.bt.co</a> content displayed pursuant to license.</div>

                <h1 className="text-size-24 text-spacing-1 font-weight-normal">{t('Play torrents while you download on Mac.')}</h1>
                <h2 className="text-size-16 text-spacing-1 font-weight-normal my-3">{t('Simple interface. Works inside your browser')}</h2>

                <div>
                  <Button onClick={(e) => {this.handleWebClick(e, webLink)}} href={webLink} id="products-utweb-for-mac" className=" btn-primary text-spacing-1 mt-3 mt-md-3 mb-4 button-filled text-spacing-1">{t(`Free Download`)}</Button>
                </div>
                <a href={`${pathname}#mac-classic`} id="products-utweb-for-mac-classic-anchor" className="d-block text-dark">{t(`Looking for µTorrent Classic for Mac?`)}</a>
              </div>
              
            </div>
            <div className="col-md-12 order-md-2 d-flex justify-content-center align-items-center flex-column">
                {/*<img src={heroImg} alt={heroImgAlt} className="img-fluid d-none d-md-block"></img>*/}

                <picture>
                  <source srcSet={`${heroImgWebp} 1x, ${heroImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${heroImg} 1x, ${heroImg2x} 2x`} src={heroImg} alt={heroImgAlt} className="img-fluid d-none d-md-block hero-img shadow-lg rounded-xl"></img>
                </picture>
                <div style={{fontSize: '10px'}} className="d-none d-md-block mb-4 text-center mt-2"><a id="web-mac-bt-now" href="https://now.bt.co/" target="_blank" rel="noopenner noreferrer" className="text-dark text-decoration-none">now.bt.co</a> content displayed pursuant to license.</div>
              </div>
          </div>
        </div>

        <div className="container-fluid overflow-hidden position-relative">
          <div className="container z-2">
            
            <div className="container d-flex justify-content-center align-items-center py-2 px-0 my-4 mt-sm-5">

                <div className="col-12 order-md-1 d-flex flex-column justify-content-center align-items-center">
                  <h2 className="font-weight-bold text-md-left feature-list-title">{t(`FeaturesHeader`)}</h2>
                  <p className="font-weight-normal mb-3 mt-3 text-center text-md-left feature-list-title-description">{t(`µTorrent Web Online Torrent Downloader Features`)}</p>              
                </div>
              </div>

            <div className = "index-features-list m-auto pb-5">
              <FeaturesList text={text} pageName={pageName}/>
            </div>
          </div>
        </div>

        <div className="container-fluid position-relative overflow-hidden px-0 mx-0">
          <div className="container-fluid panel-bg">  
            <div className="container my-5 text-center z-2">
              <p className="text-center font-weight-bold feature-list-title mb-3 pt-3">{t(`Compare`)}</p>
              <h2 className="text-center mb-5 text-size-24 font-weight-normal text-dark">
                  {t(`Compare µTorrent Web Versions`)}
              </h2>
            </div>
            <ComparisonPanels pageContext={{ "text": text, "pageName":pageName, "isMac":this.state.isMac }}>
              <p className="text-dark text-center mt-3 mt-sm-5">
                <LocalizedLink to="/web/pro/" id="products-mac-utweb-free-learnmore-premium" className="font-weight-bold">
                  {t(`Learn more`)}
                </LocalizedLink> {t(`about µTorrent Web premium products`)}
              </p>
            </ComparisonPanels>
          </div>
        </div>

        <div className="container-fluid py-5" style={backgrounds.lightBg} id="mac-classic">
  
    		<div className="container pb-md-3">
    			<div className="row text-center mt-4 mt-sm-5">
              <div className="col-md-6 mt-md-0 order-md-2 px-md-0">
                <Logo secondaryColor="black" tag="h2" productName="Classic" className="mb-4"/>
                <img src={classicScreenshot} alt={heroImgAlt} className="img-fluid d-inline-block d-md-none mb-4 align-self-center shadow-lg"></img>


                <div className="text-size-24 text-spacing-1 font-weight-normal">{t(`Download torrents in bulk with the<span class='d-block'> original Mac torrent client.</span>`)}</div>
                <ul className="mt-2 product-hero-list text-dark px-lg-5">
                	<li className="px-lg-3">
                		{t(`Torrent Mac client for fast bulk downloads`)}
                	</li>
                	<li className="px-lg-3">
                		{t(`Advanced customization to automate torrent downloads`)}
                	</li>
                	<li className="px-lg-3">
                		{t(`Supports Mac OS Mojave 10.14 and below`)}
                	</li>
                </ul>
                <div>
                  <Button onClick={(e) => {this.handleClassicClick(e, classicLink)}} href={classicLink} id="products-classic-for-mac" className=" btn-primary text-spacing-1 mt-3 mt-md-3 button-filled text-spacing-1 mb-1">{t(`Free Download`)}</Button>
                </div>
                <div>
                  <p className="d-block text-center text-size-14"> {t(`µTorrent for Mac`)}</p>
                  <img src={warning} alt="Warning Icon" style={{ height: `20px` }} className="warning-icon mr-1 d-inline pb-1"/>
                  <p className="d-inline text-left text-size-14"> {t(`Not compatible with MAC OS Catalina Version 10.15 and above`)}</p>
                </div>
              </div>
              <div className="col-md-6 mb-5 order-md-1 d-none d-md-block">
                <img src={classicScreenshot2x} alt="µTorrent Classic in-client" className="img-fluid d-none d-md-block shadow-lg"></img>
                
                {/*<picture>
                  <source srcSet={`${classicScreenshotWebp} 1x, ${classicScreenshotWebp2x} 2x`} type="image/webp"></source> 
                  <img srcSet={`${classicScreenshot} 1x, ${classicScreenshot2x} 2x`} src={classicScreenshot} alt={heroImgAlt} className="img-fluid d-block d-md-none mb-4"></img>
                </picture>*/}
                
              </div>
            </div>
          </div>
        </div>

        <TipsFaq type="tips" header={t(`Tips`)} subheader={t(`µTorrent Mac Product Tip`)}>
          <div className="col-12 px-0">
            <p className="product-faq-question">{t(`Which Mac torrent client is best for me?`)}</p>
            <p className="product-faq-answer">{t(`There’s no question that if you are new to the world of torrenting, µTorrent Web for Mac is the best option, especially if you are running Mac OS Catalina and above. The torrent software not only has a simple and intuitive interface, but it installs directly into your browser to make your Mac torrent downloads simple. If you use Google, Yahoo or Bing to search for torrents, an integrated scanner helps you identify torrents from the search results. This makes the process of searching to download safer and easier because you don’t have to visit potentially untrusted websites. Best of all, µTorrent Web for Mac is designed to play torrents, making it great for streaming content immediately after you have started a music or video-based media file.`)}</p>
            <p className="product-faq-answer">{t(`Most users looking for a Mac torrent client might be familiar with µTorrent Classic. This popular torrent client enables fast downloads of files simultaneously and includes customization options such as the ability to schedule downloads, manage bandwidth consumption and data usage, and add torrents to your Mac torrent client from another internet connected device. It’s a great choice for experienced users who are familiar with downloading and have used µTorrent in the past. However, since the introduction of Catalina version 10.15, which no longer supports 32-bit apps, µTorrent Classic is not compatible if you are running 10.15 or newer Mac OS versions.`)}</p>
          </div>
        </TipsFaq>
      </>
    )

  }
}

BittorrentWebMac.contextType = CompatibilityContext;

export default BittorrentWebMac
